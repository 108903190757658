<template>
  <v-app>
    <SplashScreen v-if="loading" />

    <Application v-else-if="$store.getters.authenticated">
      <router-view />
    </Application>

    <v-main v-else>
      <router-view />
    </v-main>

    <SnackBar />
  </v-app>
</template>

<script>
import SplashScreen from "./template/SplashScreen.vue";
import Application from "./template/Application.vue";
import SnackBar from "./components/app/SnackBar.vue";
import { mapActions } from "vuex";

export default {
  name: "App",

  components: {
    SplashScreen,
    Application,
    SnackBar,
  },
  beforeMount() {
    this.$root.$on("logoff", () => {
      this.logoff();
    });
  },
  beforeDestroy() {
    this.$root.$off("logoff");
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setToken", "setSignIn", "setSignOut"]),
    async logoff() {
      this.loading = true;
      await this.setSignOut();
      await this.$router.push({ path: "/auth" });
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
body {
  @include scrollbar();
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.test {
  @include font-source(2rem, $primary, 800, 2.5rem);
}

.pulse {
  @include pulse-effect($primary);
}
</style>
