import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "@mdi/font/css/materialdesignicons.min.css";
import { VueMaskDirective } from "v-mask";

import CustomMaskedInput from "./components/form/CustomMaskedInput.vue";
import CustomInput from "./components/form/CustomInput";
import CustomSelect from "./components/form/CustomSelect.vue";

import HeaderView from "./components/content/HeaderView";
import CustomTable from "./components/content/CustomTable";
import CustomDialog from "./components/content/CustomDialog";
import CardGrid from "./components/content/CardGrid";
import SwitchShowType from "./components/content/SwitchShowType";

import LoaderHover from "./components/loaders/LoaderHover";
import LoaderView from "./components/loaders/LoaderView";
import LoaderContent from "./components/loaders/LoaderContent";

import ConfirmationDialog from "./components/app/ConfirmationAlert.vue";
import CustomAutoComplete from "./components/form/CustomAutoComplete.vue";

Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.directive("mask", VueMaskDirective);

Vue.component("custom-input", CustomInput);
Vue.component("masked-field", CustomMaskedInput);
Vue.component("custom-select", CustomSelect);
Vue.component("custom-autocomplete", CustomAutoComplete);
Vue.component("confirmation-dialog", ConfirmationDialog);

Vue.component("loader-hover", LoaderHover);
Vue.component("loader-view", LoaderView);
Vue.component("loader-content", LoaderContent);

Vue.component("header-view", HeaderView);
Vue.component("custom-table", CustomTable);
Vue.component("custom-dialog", CustomDialog);
Vue.component("card-grid", CardGrid);
Vue.component("switch-show-type", SwitchShowType);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
