<template>
  <v-dialog
    v-model="dialog"
    ref="dialog"
    :max-width="width"
    :persistent="persistent"
  >
    <v-card color="surface">
      <div v-if="!hideHeader" class="d-flex py-4 px-8 mb-4 accent">
        <div class="d-flex column">
          <h1 class="text-h6 overtext--text">{{ title }}</h1>
          <h2 v-if="!!subtitle" class="text-body-2 subtext--text">
            {{ subtitle }}
          </h2>
        </div>

        <v-spacer />

        <v-btn class="rounded-lg" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section class="px-8 py-4">
        <slot />
      </section>

      <div v-if="!hideFooter" class="d-flex py-4 px-8 mt-4">
        <v-spacer />

        <v-btn class="mr-2 text-none" text @click="closeDialog">
          Cancelar
        </v-btn>

        <v-btn
          class="black--text text-none"
          color="primary"
          depressed
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      default: "",
    },

    width: {
      type: String,
      default: "600",
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    hideFooter: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    submit() {
      this.$emit("submit");
    },
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
