<template>
  <loader-content v-if="loading" />

  <div v-else-if="items.length > 0">
    <v-data-table
      class="custom-table rounded-lg surface elevation-1 mb-6"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      disable-sort
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
    </v-data-table>

    <v-pagination
      v-if="!!pagination && pageCount !== 1"
      v-model="page"
      color="primary"
      :length="pageCount"
      :total-visible="10"
      @input="submit()"
    />
  </div>

  <div v-else class="d-flex text-center justify-center">
    <span class="text-h6 font-weight-light">
      Nenhum resultado foi encontrado
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
    };
  },

  props: {
    headers: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    pagination: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    pageCount() {
      if (!this.pagination) return;

      let value = this.pagination.total / this.pagination.size;
      if (value % 1 !== 0) value = value + 1;

      return Math.trunc(value);
    },
  },

  methods: {
    submit() {
      this.$emit("update:pagination", this.page - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td {
  border-bottom: 0 !important;
}
</style>
