<template>
  <v-btn class="rounded-lg mx-2" small icon @click="changeTheme()">
    <v-icon>
      {{
        $vuetify.theme.dark
          ? "mdi-moon-waning-crescent"
          : "mdi-white-balance-sunny"
      }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["setDarkMode"]),

    changeTheme() {
      const value = !this.$vuetify.theme.dark;

      this.$vuetify.theme.dark = value;
      this.setDarkMode(value);
    },
  },
};
</script>

<style></style>
