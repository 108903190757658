import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@mdi/font/css/materialdesignicons.css";
import "@flaticon/flaticon-uicons/css/bold/all.css";
import "@flaticon/flaticon-uicons/css/regular/all.css";
import "@flaticon/flaticon-uicons/css/solid/all.css";

import Colors from "@/styles/app.module.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ...Colors,
      },
    },
  },
});
