<template>
  <div class="d-flex">
    <div class="accent mr-1" style="width: 16px; height: 48px" />

    <v-sheet class="clip py-2 pl-4 pr-16" color="accent">
      <h1 class="text-h5 font-weight-bold font-italic overtext--text">
        {{ title }}
      </h1>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.clip {
  clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
}
</style>
