import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/home/Home.vue"),
    meta: { requiredAuth: true },
    children: [
      {
        path: "/home",
        name: "AJF Bets",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/main/Main.vue"),
      },
      {
        path: "/users",
        name: "AJF Bets - Usuários",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/users/Users.vue"),
      },
      {
        path: "/groups",
        name: "AJF Bets - Grupos",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/groups/Groups.vue"),
      },
      {
        path: "/players",
        name: "AJF Bets - Jogadores",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/players/Players.vue"),
      },
      {
        path: "/leagues",
        name: "AJF Bets - Ligas",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/leagues/Leagues.vue"),
      },
      {
        path: "/teams",
        name: "AJF Bets - Equipes",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/teams/Teams.vue"),
      },
      {
        path: "/games",
        name: "AJF Bets - Jogos",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/games/Games.vue"),
      },
      {
        path: "/market-headers",
        name: "AJF Bets - Tipos de aposta",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/home/children/marketheaders/MarketHeaders.vue"),
      },
      {
        path: "/stats",
        name: "AJF Bets - Tipos de ações",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/stats/Stats.vue"),
      },
      {
        path: "/markets",
        name: "AJF Bets - Mercados",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/markets/Markets.vue"),
      },
      {
        path: "/matches",
        name: "AJF Bets - Partidas",
        meta: { requiredAuth: true },
        component: () => import("../views/home/children/matches/Matches.vue"),
      },
      {
        path: "/matches/:id",
        name: "AJF Bets - Editar partida",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/home/children/matches/children/MatchesForm.vue"),
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: () => import("../views/signIn/Auth.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
