<template>
  <div class="d-flex flex-column fill-height">
    <!-- app bar -->
    <v-app-bar id="nav" color="background" flat dense app>
      <v-btn class="rounded-lg ml-2 mr-4" small icon @click="mini = !mini">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <div class="text-h6">Arena Jogue Fácil Bets</div>

      <v-spacer />
      {{ user }}
      <ThemeProvider />

      <v-btn class="rounded-lg mx-2" small icon @click="logoff()">
        <v-icon>mdi-location-exit</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      color="surface elevation-1"
      floating
      app
      :mini-variant="mini"
    >
      <v-list class="transparent" nav dense>
        <router-link
          v-for="(item, i) in routes"
          v-slot="{ href, navigate, isActive }"
          :to="item.path"
          :key="i"
          custom
        >
          <v-tooltip right v-if="hasRequiredRole(item.requiredRole)">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                link
                v-bind="attrs"
                v-on="on"
                class="mb-4 px-0"
                :href="href"
                @click="navigate"
                style="max-height: 40px !important"
              >
                <v-list-item-avatar
                  :color="isActive ? 'primary' : 'accent'"
                  size="40"
                  rounded
                >
                  <v-icon :dark="isActive">{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <!-- slot view -->
    <v-main class="background">
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ThemeProvider from "@/components/app/ThemeProvider";

export default {
  name: "ApplicationView",
  data() {
    return {
      mini: false,
      drawer: true,
      permissions: this.$store.getters.permissions,
      drawerOpen: true,
    };
  },

  components: {
    ThemeProvider,
  },

  computed: {
    ...mapState(["user"]),
    user() {
      return this.$store.getters.user.name;
    },
    routes() {
      return [
        {
          name: "Usuários",
          path: "/users",
          icon: "mdi-account-key",
          requiredRole: "people",
        },
        {
          name: "Grupos",
          path: "/groups",
          icon: "mdi-group",
          requiredRole: "permission-groups",
        },
        {
          name: "Jogadores",
          path: "/players",
          icon: "mdi-account-multiple",
          requiredRole: "players",
        },
        {
          name: "Equipes",
          path: "/teams",
          icon: "mdi-shield-sword",
          requiredRole: "teams",
        },
        {
          name: "Jogos",
          path: "/games",
          icon: "mdi-controller",
          requiredRole: "sports",
        },
        {
          name: "Ligas",
          path: "/leagues",
          icon: "mdi-trophy",
          requiredRole: "leagues",
        },
        {
          name: "Partidas",
          path: "/matches",
          icon: "mdi-football-helmet",
          requiredRole: "matches",
        },
        {
          name: "Submercados",
          path: "/market-headers",
          icon: "mdi-star-circle",
          requiredRole: "market-headers",
        },
        {
          name: "Estatísticas",
          path: "/stats",
          icon: "mdi-list-status",
          requiredRole: "stats",
        },
        {
          name: "Mercados",
          path: "/markets",
          icon: "mdi-store",
          requiredRole: "markets",
        },
      ];
    },
  },

  methods: {
    clearService() {
      this.$router.push({ path: "/home" });
    },

    logoff() {
      this.$root.$emit("logoff");
    },

    hasRequiredRole(role) {
      return this.permissions.includes(role);
    },
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
  },
};
</script>

<style></style>
