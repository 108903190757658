<template>
  <loader-content v-if="loading" />

  <div v-else-if="items.length > 0">
    <v-row class="mb-6">
      <v-col v-for="(item, i) in items" cols="6" sm="4" md="3" lg="2" :key="i">
        <v-card
          color="surface"
          width="100%"
          height="100%"
          :class="`${
            item.active === undefined || item.active || 'inactive'
          } rounded-lg zoom`"
          @click="submit(item.id)"
        >
          <div class="accent">
            <v-img
              class="rounded-t-lg rounded-b-0"
              :src="item.image"
              :aspect-ratio="4 / 4"
              :contain="!fillImage"
            />
          </div>

          <section class="d-flex flex-column py-3 px-4">
            <!-- head -->
            <div class="d-flex">
              <div class="d-flex flex-column">
                <div class="text-body-1 text-xl-h6 overtext--text">
                  {{ item.title }}
                </div>

                <div v-if="!!item.subtitle" class="text-caption subtext--text">
                  {{ item.subtitle }}
                </div>
              </div>

              <v-spacer />

              <v-icon v-if="!!item.icon" color="primary">
                {{ item.icon }}
              </v-icon>
            </div>

            <!-- content -->
            <div
              v-if="!!item.text"
              class="text-body-2 text-lg-body-1 overtext--text mt-2"
            >
              <span v-html="item.text" />
            </div>
          </section>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-if="!!pagination && pageCount !== 1"
      v-model="page"
      color="primary"
      :length="pageCount"
      :total-visible="10"
      @input="handlePagination()"
    />
  </div>

  <div v-else class="d-flex text-center justify-center">
    <span class="text-h6 font-weight-light">
      Nenhum resultado foi encontrado
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
    };
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    pagination: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },

    fillImage: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    pageCount() {
      if (!this.pagination) return;

      let value = this.pagination.total / this.pagination.size;
      if (value % 1 !== 0) value = value + 1;

      return Math.trunc(value);
    },
  },

  methods: {
    submit(value) {
      this.$emit("submit", value);
    },

    handlePagination() {
      this.$emit("update:pagination", this.page - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.inactive {
  opacity: 0.25;
}

.zoom {
  transition: transform 0.25s !important;
}

.zoom:hover {
  transform: scale(1.025);
}
</style>
