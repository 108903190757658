import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    darkMode: true,
    token: null,
    user: null,
    refreshToken: null,
    permissions: null,
  },
  getters: {
    darkMode: ({ darkMode }) => darkMode,
    authenticated: ({ token, user }) => !!token && !!user,
    token: ({ token }) => token,
    user: ({ user }) => user,
    permissions: ({ permissions }) => permissions,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
      localStorage.setItem("user", payload);
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
      localStorage.setItem("token", payload);
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload;
    },
  },
  actions: {
    setSignIn({ dispatch }, payload) {
      dispatch("setToken", payload.token);
      dispatch("setUser", payload.user);
      dispatch("setRefreshToken", payload.refreshToken);
      dispatch("setPermissions", payload.permissions);
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
      dispatch("setUser", null);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setRefreshToken({ commit }, payload) {
      commit("SET_REFRESH_TOKEN", payload);
    },
    setPermissions({ commit }, payload) {
      commit("SET_PERMISSIONS", payload);
    },
  },
  modules: {},
});
