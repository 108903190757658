<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
      ref="confirmationDialog"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancel"> Cancelar </v-btn>
          <v-btn color="red darken-1" text @click="confirm"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "confirmation-dialog",
  components: {},
  mixins: [],
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    item: {
      type: [Object, String],
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    open() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
<style lang="" scoped></style>
