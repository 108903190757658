<template>
  <section class="d-flex px-4">
    <v-btn
      :color="viewType === 'grid' ? 'primary' : ''"
      class="rounded-lg accent mr-2"
      icon
      @click="submit('grid')"
    >
      <v-icon>mdi-grid</v-icon>
    </v-btn>

    <v-btn
      :color="viewType === 'list' ? 'primary' : ''"
      class="rounded-lg accent"
      icon
      @click="submit('list')"
    >
      <v-icon>mdi-view-list</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["viewType"]),
  },

  methods: {
    ...mapActions(["setViewType"]),

    submit(value) {
      this.setViewType(value);
      this.$emit("change");
    },
  },
};
</script>

<style></style>
